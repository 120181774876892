<template>
  <div class="healthDetail">
      <div class="content">
          <div class="title">{{detailInfo.title}}</div>
          <!-- <div class="banner">
              <img :src="detailInfo.img" class="banner_imgs" alt="">
          </div> -->
          <div class="info" v-html="detailInfo.content">
          </div>
      </div>
  </div>
</template>

<script>
import {topicDetail } from '@/api/studentProduct'
export default {
    data () {
        return {
            detailInfo:{}
        }
    },
    created () {
        this.getDetail(this.$route.query.id)
    },
    methods:{
        async getDetail(id) {
            const res = await topicDetail({id:id})
            if(res.data.code == 200) {
                this.detailInfo = res.data.data
            }
        }
    }
}
</script>

<style lang="less" scoped>
    .healthDetail{
        background: #F7F8F9;
        padding: 15px;
        height: 100vh;
        .content{
            border-radius: 10px;
            background-color: #fff;
            .title {
                padding: 10px 10px 15px;
                font-size: 20px;
                font-family: PingFang SC;
                font-weight: 600;
                line-height: 22px;
                color: #333333;
            }
            .banner{
                .banner_imgs{
                    width: 100%;
                }
            }
            .info {
                padding: 15px 21px;
                color: #666666;
                font-size: 12px;
                line-height: 22px;
                ::v-deep img {
					width: 100%;
				}
            }
        }
    }
</style>